import React from "react"
// Här är min kommentar
// Här kommer jonis kommentar
const Footer = () => {
  return (
    <footer>
      <p className="footer__text">
        This website is a collection of work by Stockholm-based designer and/or
        musician Gustaf Montelius.
      </p>
      <div className="footer_contact">
        <p>
          &#9998;{" "}
          <a href="mailto:gustafmontelius@gmail.com">
            {" "}
            gustafmontelius@gmail.com
          </a>
        </p>
        <p>&#9743; +46(0)708210254</p>
        <p>
          &#9744;{" "}
          <a href="https://www.instagram.com/gustafmontelius/">Instagram</a>
        </p>
        <p>&#10035; Full CV available upon request</p>
      </div>
      <div className="footer__stamp">
        <p>
          Website by Gustaf Montelius and Jonathan Undhagen. Copyright (C)2020
          Gustaf Montelius, unless otherwise noted.
        </p>
      </div>
    </footer>
  )
}

export default Footer
