import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Media from "react-media"

const Posts = ({ postsData, imageData, allMediaFilesData }) => {
  return (
    <>
      {postsData.edges.map(item => {
        return (
          <>
            <div key={item.node.title} className="desktop_post">
              <Link
                to={`/${item.node.slug}/`}
                state={{ imageIndex: 0, modal: true }}
              >
                <div className="post__title">{item.node.title}</div>
              </Link>

              <div className="post__thumbnails">
                {item.node.thumbnails.map((thumbnail, index) => {
                  const mobileImage = item.node.mobile[index]

                  return (
                    <Link
                      to={`/${item.node.slug}/`}
                      state={{
                        imageIndex: index,
                        modal: true,
                        noScroll: true,
                      }}
                    >
                      <picture>
                        <source media="(min-width: 501px)" srcSet={thumbnail} />
                        {/* Ladda in mobilbild */}
                        <source
                          media="(max-width: 500px)"
                          srcSet={mobileImage}
                        />
                        <img src={thumbnail} className={"thumbnail"} />
                      </picture>
                    </Link>
                  )
                })}
              </div>
            </div>
          </>
        )
      })}
    </>
  )
}

export default Posts
