import React from "react"
import ReactRotatingText from "react-rotating-text"
import scrollTo from 'gatsby-plugin-smoothscroll';

// import "./header.scss"

let words = [
  "Visual Inquiry™",
  "Ephemera™",
  "Fake Jazz™",
  "Spirit Animals™",
  "Divine Eggs™",
  "This & That™",
  "Crème Fraiche™",
  "A4 Stacks™",
  "Laser Jets™",
  "Lizard Teeth™",
  "Paper Jam™",
  "Occasional Architecture™",
  "Peach Jam™",
]

const Header = () => (
  <header>
    <h1 className="header__title">
      Dept. of&nbsp;
      <ReactRotatingText
        items={words}
        random={true}
        pause={3000}
        cursor={false}
      />
    </h1>
    <h2 className="header__info" onClick={() => scrollTo('footer')}>&#9432; + &#9743;</h2>
  </header>
)

export default Header
