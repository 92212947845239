/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Footer from "./Footer"
import Head from "./head"
import Header from "./header"

const Layout = ({ children }) => {
  return (
    <>
      <Head title="Gustaf Montelius" description="" />
      <Header />
      <main className="container">{children}</main>
      <Footer />
    </>
  )
}
export default Layout
