import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Posts from "../components/posts"

export const query = graphql`
  query {
    posts: allPostsJson {
      edges {
        node {
          slug
          title
          thumbnails
          media
          mobile
          description
        }
      }
    }
    allImageContent: allImageSharp {
      edges {
        node {
          fluid(maxWidth: 1600) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allMediaFiles: allFile {
      edges {
        node {
          name
          extension
          publicURL
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const { posts, allImageContent, allMediaFiles } = data

  return (
    <Layout>
      <Posts postsData={posts} imageData={allImageContent} allMediaFilesData={allMediaFiles}/>
    </Layout>
  )
}

export default IndexPage
